<template>
  <div>
    <header-slot>
      <template #actions>
        <b-button
          v-if="requestPassword"
          @click="openNewRequest()"
          variant="primary"
        >
          <b-icon icon="plus"></b-icon> New Request</b-button
        >
      </template>
    </header-slot>
    <div>
      <template v-if="requestPassword">
        <b-nav card-header pills class="m-0">
          <b-nav-item
            exact
            :active="tabActive === 1"
            :link-classes="['px-3', bgTabsNavs]"
            exact-active-class="active"
            @click="
              tabActive = 1;
              $refs.productRequestGrid.refresData(1);
            "
          >
            PENDING
            <span v-if="counter > 0" class="ml-1">
              <feather-icon
                icon
                :badge="counter > 99 ? '99+' : counter"
                badge-classes="badge-important"
              />
            </span>
          </b-nav-item>
          <b-nav-item
            exact
            :active="tabActive === 2"
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
            @click="
              tabActive = 2;
              $refs.productRequestGrid.refresData(2);
            "
          >
            IN PROCESS
          </b-nav-item>
          <b-nav-item
            exact
            :active="tabActive === 3"
            exact-active-class="active"
            :link-classes="['px-3', bgTabsNavs]"
            @click="
              tabActive = 3;
              $refs.productRequestGrid.refresData(3);
            "
          >
            DELIVERED
          </b-nav-item>
        </b-nav>
        <b-card class="border-top-primary border-3 border-table-radius">
          <request-product-grid ref="productRequestGrid"></request-product-grid>
        </b-card>
      </template>
      <template v-else>
        <b-container>
          <b-card class="offset-md-4 col-md-4 mt-5">
            <div class="text-center">
              <b-img
                src="/assets/images/helpdesk/developer.png"
                alt="Logo"
                class="img-fluid"
              />
              <div class="insert-title">Please insert password</div>
            </div>
            <b-card-body>
              <b-row>
                <b-col class="offset-md-1 col-md-10">
                  <ValidationObserver ref="form">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-form-group>
                        <label class="label-class" for="pName">Password:</label>
                        <b-form-input
                          @keyup.enter="validatePassword()"
                          type="password"
                          v-model="inputPassword"
                          placeholder="Password"
                          :class="{
                            'border border-danger': errors[0] || errorPassword,
                          }"
                        ></b-form-input>
                        <small
                          class="text-danger"
                          v-if="errors[0] || errorPassword"
                        >
                          This field {{ errors[0] }} or Password is incorrect
                        </small>
                      </b-form-group>
                    </ValidationProvider>
                  </ValidationObserver>
                </b-col>
                <b-col cols="12" class="text-center">
                  <b-button variant="primary" @click="validatePassword()">
                    Check Password
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-container>
      </template>
    </div>

    <modal-new-product-request
      v-if="modalNewRequest"
      @close="closeModalNewRequest()"
    ></modal-new-product-request>
  </div>
</template>
  
  <script>
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";

import RequestPorductGrid from "@/views/commons/components/product-request/components/RequestProductGrid.vue";

import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    "request-product-grid": RequestPorductGrid,
  },
  data() {
    return {
      tabActive: 1,
      newIssueShow: false,
      inputPassword: "",
      isValidPassword: false,
      sendPassword: false,
      counter: 0,
      modalNewRequest: false,
      openSection: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    errorPassword() {
      return this.sendPassword && !this.isValidPassword;
    },
    requestPassword() {
      if (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 17 ||
        this.currentUser.role_id == 11 ||
        this.currentUser.eng_soft
      ) {
        return true;
      }
      this.openSection = false;
      return this.isValidPassword;
    },
  },
  created() {
    this.getCountTab();
  },
  mounted() {
    this.isValidPassword = localStorage.getItem("user-logged");
    localStorage.removeItem("user-logged");
  },
  methods: {
    openNewRequest() {
      localStorage.setItem("user-logged", true);
      this.$router.push("./create-product-request");
    },
    openModalNewRequest() {
      this.modalNewRequest = true;
    },
    closeModalNewRequest() {
      this.modalNewRequest = false;
    },
    async getCountTab() {
      const params = {
        modul_id: this.currentUser.modul_id,
        user_id: this.currentUser.id,
      };
      const result = await helpdeskService.getCountTab(params);
      this.counter = result.total;
    },
    refresh() {
      this.$refs.productRequestGrid.refresh();
    },
    async validatePassword() {
      if (this.inputPassword == "" || this.inputPassword == null) {
        return;
      }
      try {
        this.addPreloader();
        const params = {
          module_id: this.currentUser.modul_id,
          password: this.inputPassword,
        };
        const data = await helpdeskService.getPassword(params);
        this.removePreloader();
        if (data > 0) {
          this.isValidPassword = true;
        } else {
          this.isValidPassword = false;
          this.inputPassword = "";
          swal.fire("Oops", "Password is incorrect", "warning");
        }
      } catch {
        swal.fire("Oops", "Something went wrong", "warning");
      }
    },
  },
};
</script>
  
  <style scoped>
.insert-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.img-fluid {
  max-width: 50%;
  height: auto;
}
.label-class {
  font-size: 1rem;
  font-weight: bold;
}
</style>
  