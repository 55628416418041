<template>
  <div>
    <filter-slot
      :filter="arrayFilters"
      :filter-principal="filterPrincipal"
      :total-rows="total_data"
      :paginate="paginate"
      :start-page="start_page"
      :to-page="to_page"
      :send-multiple-sms="false"
      @reload="$refs.refProductRequest.refresh()"
    >
      <template #table>
        <b-table
          ref="refProductRequest"
          class="position-relative"
          :items="myProvider"
          stacked="lg"
          :fields="arrayColumns"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          small
          hover
          no-border-collapse
          sticky-header="65vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="search_input"
          thead-class="text-center"
          tbody-class="text-center"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(number_list)="data">
            <div>
              #{{ data.item.number_list }}
            </div>
          </template>

          <template #cell(name_module)="data">
            <div>
              {{ data.item.name_module }}
            </div>
          </template>

          <template #cell(total_amount_d)="data">
            <div>$ {{ data.item.total_amount_d|currency }}</div>
          </template>

          <template #cell(total_amount_s)="data">
            <div>S/ {{ data.item.total_amount_s|currency }}</div>
          </template>

          <template #cell(request_by)="data">
            <div>
              {{ data.item.created_at | myGlobalDay }}
              <br />
              {{ data.item.request_by }}
            </div>
          </template>

          <template #cell(status)="data">
            <div>
              <b-badge :variant="statusColor(data.item.status)">{{
                data.item.status
              }}</b-badge>
            </div>
          </template>

          <template #cell(actions)="data">
            <div>
              <feather-icon
                icon="EyeIcon"
                size="15"
                class="cursor-pointer text-primary"
                @click="openModalviewlist(data.item)"
              />
              <feather-icon
                icon="SendIcon"
                size="15"
                class="cursor-pointer ml-1 text-success"
                @click="deliveredList(data.item)"
                v-if="(status == 2 && currentUser.modul_id == 19)"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-list-products
      v-if="modalViewListProducts"
      :dataList="this.dataList"
      @close="closeModalViewList"
    />
  </div>
</template>

<script>
import fields from "@/views/commons/components/product-request/components/fields.data";
import filters from "@/views/commons/components/product-request/components/filters.data";

import RequestProductService from "@/views/commons/components/product-request/services/request-product.service";

import ModalListProducts from "@/views/commons/components/product-request/components/modals/ModalListProducts.vue";

import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
    "modal-list-products": ModalListProducts,
  },
  data() {
    return {
      sortBy: "created_at",
      sortDesc: true,
      status: 1,
      arrayColumns: fields,
      search_input: "",
      start_page: 0,
      total_data: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      to_page: 0,
      isBusy: false,
      perPageOptions: [10, 25, 50, 100],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search list ID...",
        model: "",
      },
      arrayFilters: filters,
      modalViewListProducts: false,
      dataList: null,
    };
  },

  computed: {
    // headersTable() {
    //   return this.arrayColumns.filter((field) => field.visible);
    // },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  created() {},
  methods: {
    ...mapMutations({
      refreshCounterSidebar: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    async myProvider() {
      try {
        const params = {
          name_text: this.filterPrincipal.model,
          from: this.arrayFilters[0].model,
          to: this.arrayFilters[1].model,
          status: this.status,
          moduleId: this.currentUser.modul_id,
          order: "created_at",
          orderby: "desc",
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
        };
        const response = await RequestProductService.getProductsRequest(params);
        let { data, from, to, current_page, per_page, last_page, total } =
          response.data;
        const items = data;
        this.start_page = from;
        this.to_page = to;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.next_page = this.start_page + 1;
        this.end_page = last_page;
        this.total_data = total;

        return items || [];
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    refresh() {
      this.$refs.refProductRequest.refresh();
    },
    refresData(status){
      this.status = status;
      this.$refs.refProductRequest.refresh();

      // this.filters.forEach((item) => {
      //   item.model = null;
      // });
      // this.$refs.refTickets.refresh();
      // if (status == 5) {
      //   this.filters[4].visible = false;
      //   this.arrayColumns[11].visible = false;
      // } else {
      //   this.filters[4].visible = true;
      //   this.arrayColumns[11].visible = true;
      // }
    },
    statusColor(status) {
      if (status == "PENDING") {
        return "secondary";
      } else if (status == "IN PROCESS") {
        return "primary";
      } else {
        return "success";
      }
    },
    openModalviewlist(dataList) {
      this.dataList = dataList;
      this.modalViewListProducts = true;
    },
    closeModalViewList(num) {
      this.modalViewListProducts = false;
      if(num == 1){
        this.refresh();
      }
    },
    async deliveredList(dataList){
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          const params = {
            idList: dataList.id,
          };
          const data = await RequestProductService.deliveredList(params);
          if (data.status === 200) {
            this.refresh();
            this.showSuccessSwal();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    // async getStatuses() {
    //   const response = await helpdeskService.getStatuses();
    //   response.data.forEach((item) => {
    //     this.arrayFilters[4].options.push({
    //       value: item.id,
    //       label: item.name,
    //     });
    //   });
    // },
  },
};
</script>