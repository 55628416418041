export default [
    {
        key: "number_list",
        label: "LIST ID",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "name_module",
        label: "DPTO",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "request_by",
        label: "REQUEST BY",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "total_amount_d",
        label: "TOTAL DOLLARS",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "total_amount_s",
        label: "TOTAL SOLES",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "status",
        label: "STATUS",
        class: "text-center",
        sortable: false,
        visible: true,
    },
    {
        key: "actions",
        label: "ACTIONS",
        class: "text-center",
        sortable: false,
        visible: true,
      },
  ];