export default [
    {
      type: "datepicker",
      cols: 6,
      margin: true,
      showLabel: true,
      label: "From",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
    },
    {
      type: "datepicker",
      cols: 6,
      margin: true,
      showLabel: true,
      label: "To",
      placeholder: "Date",
      class: "font-small-3",
      model: null,
      locale: "en",
      dateFormatOptions: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
    },
  //   {
  //     type: "select",
  //     margin: true,
  //     showLabel: true,
  //     label: "Module",
  //     model: null,
  //     options: [],
  //     reduce: "id",
  //     selectText: "module_name",
  //     cols: 12
  // },
];