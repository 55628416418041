<template>
  <div>
    <b-modal
      modal
      :title="'LIST PRODUCTS (#' + dataList.number_list + ')'"
      v-model="ifModal"
      size="lg"
      modal-class="modal-primary"
      hide-footer
      body-class="mb-2"
      @hidden="closeModal(null)"
      title-tag="h3"
    >
      <div>
        <validation-observer ref="form">
          <b-row>
            <b-col md="6" class="mb-2">
              <b-input-group prepend="DPTO:" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="inline-form-input-username"
                  v-model="dataList.name_module"
                  disabled
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="3" class="mb-2">
              <b-input-group prepend="TOTAL S/:" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="inline-form-input-username"
                  v-model="dataList.total_amount_s"
                  disabled
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="3" class="mb-2">
              <b-input-group prepend="TOTAL $:" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="inline-form-input-username"
                  v-model="dataList.total_amount_d"
                  disabled
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col lg="12" md="6" class="mb-2">
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 35%">PRODUCT</th>
                      <th style="width: 5%">QUANTITY</th>
                      <th>COST. U</th>
                      <th>COST TOTAL</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(list, index) in listProducts" :key="index">
                      <td>
                        {{ list.product }} <br />
                        <b-badge variant="info">CAT:</b-badge>
                        {{ list.nameCategory }}
                      </td>
                      <td class="text-center">{{ list.quantity }}</td>
                      <td>{{ list.currency }} {{ list.cost }}</td>
                      <td>
                        {{ list.currency }}
                        {{ (list.quantity * list.cost).toFixed(2) }}
                      </td>
                      <td class="text-center">
                        <b-form-checkbox v-if="(!dataList.date_delivery && currentUser.modul_id == 19)" v-model="list.status" />
                        <div v-if="dataList.date_delivery">
                          <b-badge variant="success" v-if="list.status == 4">ACCEPTED</b-badge>
                          <b-badge variant="danger" v-if="list.status == 5">REJECTED</b-badge>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col md="12">
              <b-row class="justify-content-between">
                <b-col md="5">
                  <div>
                    <label>Date delivery</label>
                    <ValidationProvider v-slot="{ errors }" rules="required">
                      <b-form-datepicker
                        id="example-datepicker"
                        v-model="dateDelivery"
                        class="mb-2"
                        :state="errors[0] ? false : null"
                        :disabled="disabled"
                      ></b-form-datepicker>
                    </ValidationProvider>
                  </div>
                </b-col>
                <b-col md="4">
                  <b-button
                    v-if="(!disabled && currentUser.modul_id == 19)"
                    variant="primary"
                    class="float-right mt-2"
                    @click="saveListDeliveryDate()"
                  >
                    <feather-icon icon="SaveIcon" size="15" />
                    SAVE
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
  
  <script>
// Import Services
import RequestProductService from "@/views/commons/components/product-request/services/request-product.service";
// Import Data
import { mapGetters, mapMutations } from "vuex";
export default {
  props: {
    dataList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ifModal: true,
      listProducts: null,
      dateDelivery: null,
      disabled: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    functionDisabled(){
        if(this.dataList.date_delivery){
            this.dateDelivery = this.dataList.date_delivery;
            this.disabled = true;
        };
    },
    async getProductsFromList() {
      try {
        this.isBusy = true;
        const response = await RequestProductService.getProductsFromList({
          idList: this.dataList.id,
        });
        if (response.status == 200) {
          this.listProducts = response.data;
          this.isBusy = false;
        }
      } catch (error) {
        console.error(error);
        this.isBusy = false;
      }
    },
    async saveListDeliveryDate() {
      let success = await this.$refs.form.validate();
      if (!success) {
      } else {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          try {
            const params = {
              idList: this.dataList.id,
              dateDelivery: this.dateDelivery,
              listProducts: this.listProducts,
            };
            const data = await RequestProductService.saveListDeliveryDate(params);
            if (data.status === 200) {
              this.showSuccessSwal();
              this.closeModal(1);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    closeModal(num) {
      this.$emit("close", num);
    },
  },
  created() {
    this.getProductsFromList();
    this.functionDisabled();
  },
};
</script>